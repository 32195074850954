.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  
  max-width: 550px;
  background: #23252C;
  padding: 25px 0 25px 0;
}

header {
  overflow: hidden;
}

a {
  text-decoration: none;
}

img.photo {
  height: 320px;
  pointer-events: none;
  border-radius: 10px 10px 0 0;
}

.App-link {
  color: #61dafb;
}

div.information {
  width: 320px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

h1.dev-name {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  margin-bottom: 8px;
}

h5.dev-title {
  font-weight: 400;
  font-size: 12.8px;
  line-height: 15px;
  text-align: center;

  color: #F3BF99;
  margin: 0 0 10px 0;
}

p.dev-site {
  margin: 0 0 25px 0;
}

a.dev-link {
  font-weight: 400;
  font-size: 10.24px;
  line-height: 150%;
  text-align: center;
  color: #F5F5F5;
}

div.dev-info {
  text-align: left;
  margin: 10px 0 30px 0;
  padding: 0 25px 0 35px;
}

h3.dev-info-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%; /* 24px */
  color: #F5F5F5;
  margin: 16px 0 8px 0;
}

p.dev-info-text {
  font-weight: 400;
  font-size: 10.24px;
  line-height: 150%;
  color: #DCDCDC;
}
div.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 10px 0 10px 0;
}

button.email-button {

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 34px;
  padding: 9px 13px 9px 11px;
  gap: 8px;

  /* white */
  background: #FFFFFF;

  /* gray/300 */
  border: 1px solid #D1D5DB;

  /* shadow/sm */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

a.email-button-text {
  /* gray/700 */
  color: #374151;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

button.linkedin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 13px 9px 11px;
  gap: 8px;
  
  width: 115px;
  
  background: #5093E2;
  
  /* shadow/sm */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
a.linkedin-button-text {
  /* gray/700 */
  color: #FFFFFF;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

span.link-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-right: 8px;
}

div.footer-links {
  margin: 0 auto;
  max-width: 320px;
  background: #161619;
  border-radius: 0px 0px 10px 10px;
  height: 45px;
  padding-top: 20px;
}

a.footer-icon {
  margin: 10px 0 10px 0;
  font-size: 25px;
  color: #918E9B;
  padding: 0 8px 0 8px;
}